<template>
	<div>
		<div class="card align-item-center px-1 py-0 flex-lg-row justify-content-md-between p-2">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" role="tabslits">
				<b-nav-item
					link-classes="px-1 justify-content-between w-100"
					class="x-tab-width"
					:to="{ name: 'posturas-sr-pendientes' }"
					:active="
						[
							'posturas-sr-pendientes',
							'posturas-sr-activas',
							'posturas-sr-por finalizar',
							'posturas-sr-finalizadas',
							'posturas-sr-anuladas',
							'padrillos-con-posturas-sr',
							'padrillos-sin-posturas-sr',
							'madrillas-con-posturas-sr',
							'madrillas-sin-posturas-sr',
						].includes($route.name)
					"
				>
					<span>Posturas</span>
				</b-nav-item>
				<b-nav-item
					link-classes="px-1 justify-content-between w-100"
					class="x-tab-width"
					:to="{ name: 'insemination-sr-pendientes' }"
					:active="['insemination-sr-pendientes', 'insemination-sr-realizadas'].includes($route.name)"
				>
					<span>Inseminación</span>
					<b-badge v-if="pending > 0" pill variant="danger" class="ml-1">
						{{ pending }}
					</b-badge>
				</b-nav-item>
			</ul>
		</div>

		<div class="card align-item-center p-1 flex-lg-row justify-content-md-between">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" role="tabslits">
				<b-nav-item
					link-classes="px-1 justify-content-between w-100"
					class="x-tab-width"
					:active="'insemination-sr-pendientes' == $route.name"
					:to="{ name: 'insemination-sr-pendientes' }"
					@click="GET_COUNTER_PENDINGS"
				>
					<span>{{ "Pendientes" }}</span>
					<b-badge v-if="pending > 0" pill variant="danger" class="ml-1">
						{{ pending }}
					</b-badge>
				</b-nav-item>
				<b-nav-item
					link-classes="px-1 justify-content-between w-100"
					class="x-tab-width"
					:active="'insemination-sr-realizadas' == $route.name"
					:to="{ name: 'insemination-sr-realizadas' }"
					@click="GET_COUNTER_PENDINGS"
				>
					<span>{{ "Realizados" }}</span>
				</b-nav-item>
			</ul>
		</div>

		<router-view></router-view>
	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
	name: "InseminationMain",
	computed: {
		...mapState("encasteInsemination", ["pending"]),
	},
	data() {
		return {
			tabIndex: 0,
			counter: 0,
		};
	},
	methods: {
		...mapMutations("encasteInsemination", ["GET_COUNTER_PENDINGS"]),
	},
};
</script>

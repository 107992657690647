var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card align-item-center px-1 py-0 flex-lg-row justify-content-md-between p-2"},[_c('ul',{staticClass:"nav nav-tabs align-items-center gap-x-2 mb-0 w-100",attrs:{"role":"tabslits"}},[_c('b-nav-item',{staticClass:"x-tab-width",attrs:{"link-classes":"px-1 justify-content-between w-100","to":{ name: 'posturas-sr-pendientes' },"active":[
						'posturas-sr-pendientes',
						'posturas-sr-activas',
						'posturas-sr-por finalizar',
						'posturas-sr-finalizadas',
						'posturas-sr-anuladas',
						'padrillos-con-posturas-sr',
						'padrillos-sin-posturas-sr',
						'madrillas-con-posturas-sr',
						'madrillas-sin-posturas-sr' ].includes(_vm.$route.name)}},[_c('span',[_vm._v("Posturas")])]),_c('b-nav-item',{staticClass:"x-tab-width",attrs:{"link-classes":"px-1 justify-content-between w-100","to":{ name: 'insemination-sr-pendientes' },"active":['insemination-sr-pendientes', 'insemination-sr-realizadas'].includes(_vm.$route.name)}},[_c('span',[_vm._v("Inseminación")]),(_vm.pending > 0)?_c('b-badge',{staticClass:"ml-1",attrs:{"pill":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.pending)+" ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"card align-item-center p-1 flex-lg-row justify-content-md-between"},[_c('ul',{staticClass:"nav nav-tabs align-items-center gap-x-2 mb-0 w-100",attrs:{"role":"tabslits"}},[_c('b-nav-item',{staticClass:"x-tab-width",attrs:{"link-classes":"px-1 justify-content-between w-100","active":'insemination-sr-pendientes' == _vm.$route.name,"to":{ name: 'insemination-sr-pendientes' }},on:{"click":_vm.GET_COUNTER_PENDINGS}},[_c('span',[_vm._v(_vm._s("Pendientes"))]),(_vm.pending > 0)?_c('b-badge',{staticClass:"ml-1",attrs:{"pill":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.pending)+" ")]):_vm._e()],1),_c('b-nav-item',{staticClass:"x-tab-width",attrs:{"link-classes":"px-1 justify-content-between w-100","active":'insemination-sr-realizadas' == _vm.$route.name,"to":{ name: 'insemination-sr-realizadas' }},on:{"click":_vm.GET_COUNTER_PENDINGS}},[_c('span',[_vm._v(_vm._s("Realizados"))])])],1)]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }